@import "../../styles/mixins";

.result {
  :global {
    .ant-result-title {
      @include typography(var(--medium-font-weight), 16px, 20px);
    }
  }
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: #ffffff;
  }
}
