@import "../../styles/extended";
@import "../../styles/mixins";

.header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  gap: 20px;

  p {
    @extend .auth-links;
  }

  .general-text {
    color: var(--color-secondary);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.28px;
  }

  .link {
    color: var(--light-color-text-selected, #0c66e4);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.28px;
    white-space: nowrap;
  }
}

@include mobile {
  .header {
    padding: 20px 10px;

    img {
      width: 140px;
    }

    .right-content {
      width: 100%;
      font-size: 12px;
      line-height: 18px;

      .description {
        padding: 1rem 1.5rem;
      }
    }
  }
}
