.layout {
  min-height: 100vh;

  .content-wrapper {
    position: relative;
    height: 100%;
    display: grid;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url("../../../assets/images/bg-image.webp");
      opacity: 0.04;
      z-index: -1;
    }
  }
}
