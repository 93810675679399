@import "./mixins";

.input {
  border-color: var(--color-input-border);
  border-radius: 3px;
  height: 32px;

  &:focus {
    border-color: var(--color-primary);
  }

  &:hover {
    border-color: var(--color-primary);
  }

  &:placeholder-shown {
    color: var(--color-secondary);
    font-weight: 400;
  }

  &::-moz-placeholder {
    color: var(--color-secondary);
    font-weight: 400;
  }
}

.custom-antd-form {
  .ant-form-item {
    margin-bottom: 19px;

    .ant-form-item-label {
      padding-bottom: 0px;

      & > label {
        color: var(--color-secondary);
        font-weight: 500;
        line-height: 18px;

        &.ant-form-item-required {
          &:after {
            content: "*";
            color: var(--color-field-required);
            visibility: visible;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.custom-antd-input {
  @extend .input;

  .ant-input-suffix {
    color: var(--color-secondary);
  }
}

.custom-antd-divider {
  color: #091e42;
}

.custom-antd-button {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 500;

  &.ant-btn-default {
    width: max-content;
    border: none;
    background-color: var(--color-button-primary);
    box-shadow: none;
    color: var(--color-heading);
  }

  &.ant-btn-link {
    width: auto;
  }
}

.custom-antd-select {
  &.ant-select {
    .ant-select-selector {
      border-color: var(--color-input-border);
      border-radius: 3px;
      height: 32px;

      &:placeholder-shown {
        color: var(--color-secondary);
        font-weight: 400;
      }

      &::-moz-placeholder {
        color: var(--color-secondary);
        font-weight: 400;
      }
    }

    .ant-select-clear {
      width: 24px !important;
    }

    .ant-select-arrow {
      color: var(--color-heading);
    }

    &.ant-select-multiple .ant-select-selector {
      height: auto;
      min-height: 32px;
    }
  }
}

.custom-antd-radio {
  color: var(--color-secondary);
}

.custom-antd-datePicker {
  border-radius: 3px;
}

.custom-antd-checkbox {
  color: var(--color-secondary);
}

.custom-ant-radio {
  .ant-radio-wrapper {
    border: 1px solid var(--color-input-border);
    padding: 8px 11px;
    @include border-radius(3px);
    color: var(--color-secondary);
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 500;

    &:hover(.ant-radio-wrapper-disabled) {
      border-color: var(--color-primary);
    }

    &.ant-radio-wrapper-checked {
      border-color: var(--color-primary);
      background-color: var(--color-primary);
      color: white;

      .ant-radio-inner {
        border-color: white;
      }
    }
  }
}

.ant-select-dropdown {
  box-shadow: 0px 8px 12px 0px var(--color-select-dropdown-box-shadow);
  border-radius: 3px;

  .ant-select-item {
    padding: 8px;

    .ant-select-item-option-content {
      color: var(--color-secondary);
      font-weight: 500;
    }
  }
}

.ant-input-number,
.ant-input-number-affix-wrapper {
  width: 100%;
  border-radius: 3px;

  .ant-input-number-suffix {
    color: var(--color-secondary);
  }
}

//When addOnAfter is used with Input Number
.ant-input-number-group-wrapper {
  .ant-input-number-group-addon {
    border-radius: 3px;
    background-color: transparent;

    .ant-select-selection-item {
      color: var(--color-secondary);
    }
  }
}

.ant-input-number-group-addon {
  .ant-form-item {
    .ant-form-item-control-input {
      min-height: 30px;

      .ant-select-selector {
        height: 30px;
      }

      .ant-select-arrow {
        top: 50%;
      }
    }
  }
}

.custom-antd-modal {
  &.ant-modal {
    padding-bottom: 0px;
    border-radius: 8px;
  }

  .ant-modal-content {
    border-radius: 8px;
    padding: 0px;

    .ant-modal-header {
      padding: 24px 16px;
      background-color: var(--color-content-background);
      margin-bottom: 0;

      .ant-modal-title {
        color: var(--color-heading);
        font-size: 20px;
        font-weight: 400;
      }
    }

    .ant-modal-body {
      padding: 16px;
    }

    .ant-modal-footer {
      margin-top: 0;
      padding: 24px 16px;
      background-color: var(--color-content-background);
      border-radius: 0 0 8px 8px;
    }
  }
}

// For Admin
.admin-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding: 20px;
    }

    .ant-modal-header {
      padding: 16px 20px;
      background-color: white;
      margin-bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .ant-modal-header .ant-modal-title {
      color: rgba(0, 0, 0, 0.9);
      @include typography(var(--bold-font-weight), 18px, 20px);
    }

    .ant-modal-footer {
      padding: 16px 20px;
      border-radius: 0 0 8px 8px;
      background: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.admin-button {
  padding: 10px 17px !important;
  font-size: 14px;
  line-height: 20px;
  @include flex();
  border-radius: 6px;

  &.ant-btn-default:not(.feature-btn) {
    width: 100%;
    border: 1px solid var(--color-input-border);
    background: none;
    box-shadow: none;
  }

  &.feature-btn {
    border: 0.5px solid var(--color-input-border);
    padding: 12px 16px !important;
    line-height: 17px;
    background: none;
    color: var(--color-feature-btn);

    &.ant-btn:not(.ant-btn-icon-only) > .ant-btn-icon:not(:last-child) {
      margin-inline-end: 8px;
    }

    &.focus-btn {
      border: 2px solid var(--color-primary);
      color: var(--color-primary);
      font-weight: var(--medium-font-weight);
    }

    &.active-button {
      border: 0.5px solid var(--color-primary);
      color: var(--color-primary);
    }
  }

  &.primary-default {
    color: var(--color-primary);
    border-color: var(--color-primary) !important;

    &:not(.ant-button-disabled):hover {
      color: var(--color-primary-light);
      border-color: var(--color-primary-light);
    }
  }

  &.ant-btn:not(.ant-btn-icon-only) > .ant-btn-icon:not(:last-child) {
    margin-inline-end: 8px;
  }
}

.admin-input {
  &.ant-input {
    border-radius: 4px;
  }
}

.admin-select {
  &.ant-select {
    .ant-select-selector {
      border-radius: 4px;
    }
  }
}

.ant-modal-body {
  overflow: scroll;
  position: relative;
}
