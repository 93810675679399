@import "../../styles/breakpoints";

.container {
  width: 1320px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.content-container {
  padding: 0px 120px;
}

@media (max-width: $desktop-large) {
  .content-container {
    padding: 0px;
  }
  .container {
    width: 1140px;
  }
}

@media (max-width: $desktop) {
  .container {
    width: 960px;
  }
}

@media (max-width: $tablet) {
  .container {
    width: 720px;
  }
}

@media (max-width: $mobile) {
  .container {
    width: 540px;
  }
}

@media (max-width: $mobile-small) {
  .container {
    width: 100%;
  }
  .content-container {
    padding: 15px;
  }
}
