@import "../../../styles/mixins";

.auth-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;

  .left-content {
    padding: 95px 0px;
    flex: 1;
    background-color: var(--color-auth-background);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-content {
    width: 43%;
    background: url("../../../assets/images/loginBanner.webp") no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 3rem 5rem;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 24.5px;

    .title {
      background-color: var(--color-primary);
      width: fit-content;
      padding: 8px 17px 8px 10px;
      border-radius: 48px;
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        padding: 11px 12px;
        border-radius: 50%;
        background-color: var(--color-image-background);
      }

      margin-bottom: 20px;
    }

    .description {
      background: var(--color-description-glass-background);
      border-radius: 10px;
      backdrop-filter: blur(60px);
      -webkit-backdrop-filter: blur(15.8px);
      padding: 2rem 2.5rem;
    }
  }
}

@include mobile {
  .auth-wrapper {
    display: block;

    .right-content {
      width: 100%;
      font-size: 12px;
      line-height: 18px;

      .description {
        padding: 1rem 1.5rem;
      }
    }
  }
}

@include small-tablet {
  .auth-wrapper {
    display: block;

    .right-content {
      width: 100%;
      font-size: 12px;
      line-height: 18px;

      .description {
        padding: 1rem 1.5rem;
      }
    }
  }
}

@include tablet {
  .auth-wrapper {
    .right-content {
      font-size: 12px;
      line-height: 18px;

      .description {
        padding: 1rem 1.5rem;
      }
    }
  }
}
