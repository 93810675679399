@import "../../../styles/mixins";

.top-wrapper {
  display: flex;
  margin: 20px 0 20px;

  .name {
    font-size: 22px;
    line-height: 20px;
    padding: 10px 10px 0px 0px;
    color: rgb(23, 71, 158);
    font-weight: 600;

    @include mobile {
      font-size: 15px;
    }
  }

  .img-wrapper {
    height: 50px;
    display: flex;
    align-items: center;
    padding-right: 24px;

    .home-img {
      width: 250px;
      max-width: 100%;

      @include mobile {
        width: 180px;
      }
    }
  }
}

.chart-card,
.table-card,
.block-card {
  border-color: var(--color-input-border);

  :global {
    .ant-card-head {
      font-size: 14px;
      min-height: 48px;
      color: white;
      background-color: var(--color-primary);

      @include mobile {
        padding: 10px;
      }

      .ant-card-head-wrapper {
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;

        .ant-card-head-title {
          overflow: auto;
          white-space: normal;
          flex-basis: 40%;

          @include mobile {
            text-align: center;
          }
        }

        .ant-card-extra {
          margin-inline-start: 0;
        }
      }
    }
  }
}

.block-card {
  :global {
    .ant-card-body {
      display: flex;
      flex-wrap: wrap;
      padding: 16px;
    }
  }

  .block-card-item {
    margin: 8px;
    background-color: #e3ebf8;
    border: 1px solid var(--color-input-border);
    border-radius: 6px;
    padding: 8px 12px;
    max-width: 240px;
    min-width: 150px;
    width: calc(50% - 16px);

    .block-card-title {
      font-size: 18px;
    }

    .block-card-sub-title {
      font-size: 12px;
      color: var(--color-secondary);
    }
  }
}

.table-card {
  .action-button {
    all: unset;
    color: var(--color-primary);
    @include flex();
    gap: 5px;
    white-space: nowrap;
    cursor: pointer;

    svg {
      min-width: fit-content;
    }
  }

  .create-btn {
    &:global(.ant-btn-default) {
      height: unset;
      background-color: white;
      color: var(--color-primary);
    }
  }

  :global {
    .ant-card-body {
      min-height: 273px;
      padding: 12px 16px;
    }

    .ant-table-wrapper .ant-table {
      border: 1px solid var(--color-input-border);
      border-radius: 8px;

      .ant-table-thead .ant-table-cell {
        font-size: 13px;
        background-color: #18479e;
        color: white;

        &::before {
          display: none;
        }
      }

      .ant-table-tbody {
        .ant-table-cell {
          font-size: 12px;
        }

        tr:last-child td {
          border-bottom: none;
        }
      }
    }
  }
}

.banner-container {
  width: 100% !important;
  height: unset !important;
  line-height: unset !important;
  padding: 0 !important;
  margin-bottom: 20px;

  .marquee-container {
    overflow: hidden;
    width: 100%;
    position: relative;
    @include typography(var(--medium-font-weight), 18px);
    min-height: 48px;
    background-color: #f15353;
    color: white;
    border-radius: 4px;
    @include flex();

    .marquee {
      width: auto;
      display: inline-block;
      animation: marqueeAnimation linear infinite;
      position: absolute;
      white-space: nowrap;
      left: 100%;

      &.paused {
        animation-play-state: paused;
      }
    }

    @keyframes marqueeAnimation {
      0% {
        left: 100%;
      }

      100% {
        transform: translateX(-100%);
        left: 0%;
      }
    }
  }
}

@include mobile {
  .charts-wrapper {
    padding: 0 12px 0 24px;
    max-width: calc(100vw + 12px);

    .block-card-item {
      max-width: unset !important;
      width: 100% !important;
    }
  }
}
