@import "../../../styles/mixins";

.success-img {
  @include flex(column);
  gap: 20px;
}
.message {
  @include typography(var(--medium-font-weight), 16px, 20px);
  margin: 20px auto;
  text-align: center;
}
