@import "../../styles/mixins";

.status-wrapper {
  @include flex(row, flex-start);
  gap: 12px;
  flex-wrap: wrap;

  .status {
    @include flex();
    gap: 4px;
    @include typography(var(--medium-font-weight), 12px, 16px);
    border-radius: 8px;
    padding-right: 8px;
    height: 16px;
    flex-shrink: 0;

    img,
    svg {
      margin-left: -1px;
    }
  }
}