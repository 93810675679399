@import "../../../../styles/mixins";

.bookings-modal-wrapper {
  :global {
    .ant-timeline-item-head-custom {
      padding: 0;
    }

    .ant-timeline .ant-timeline-item .ant-timeline-item-tail {
      border-inline-start-color: #091e4224;
    }

    .ant-timeline .ant-timeline-item-last {
      padding-bottom: 0;

      .ant-timeline-item-content {
        min-height: unset;
      }
    }
  }

  .tracking-item-wrapper {
    .tracking-item-header {
      color: #172b4d;
      @include typography(var(--regular-font-weight), 14px, 18px);
      padding-top: 4px;
    }

    .lighter-text {
      color: #626f86;
      @include typography(var(--regular-font-weight), 12px, 18px);
    }

    .tracking-item-light-header {
      color: #172b4d;
      @include typography(var(--regular-font-weight), 12px, 18px);
    }

    .tracking-item-inner-wrapper {
      .tracking-data-wrapper {
        border-radius: 4px;
        background: rgba(9, 30, 66, 0.05);
        padding: 12px;
        margin: 12px 0px;

        .data-heading {
          color: #172b4d;
          @include typography(var(--regular-font-weight), 12px, 18px);
          margin-bottom: 4px;
        }

        .data-value {
          color: #626f86;
          @include typography(var(--regular-font-weight), 12px, 18px);
        }
      }
    }
  }
}
