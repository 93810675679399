@import "../../styles/extended";
@import "../../styles/mixins";

.signup-form {
  width: 60%;

  h1 {
    @extend .auth-heading;
  }

  .policy-text {
    @extend .auth-links;
    padding: 20px 0px;
  }

  .create-account-title {
    color: var(--color-heading);
    font-size: 29px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.58px;
  }

  .mobile-input-wrapper {
    height: 32px;

    input {
      height: 32px;
    }
  }

  .password-validation-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 20px;
    margin: 10px 0px 19px 4px;

    .validation {
      align-items: center;
      display: flex;
      gap: 8px;

      .dot {
        background-color: #d9d9d9;
        border-radius: 50px;
        height: 8px;
        width: 8px;
      }

      .validation-name {
        color: rgba(0, 0, 0, 0.4);
        @include typography(var(--regular-font-weight), 12px, 21px);
      }
    }

    .validated {
      .dot {
        background-color: #14b467;
      }

      .validation-name {
        color: #14b467;
      }
    }
  }
}

.signup-disable-wrapper {
  @include flex(column);
  gap: 20px;

  .message {
    @include typography(var(--medium-font-weight), 16px, 20px);
    text-align: center;
  }
}

@include mobile {
  .signup-form {
    width: 100%;
    padding: 52px 20px 0;
  }
}
