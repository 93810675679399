@import "../../../styles/mixins";
.fixed-header {
  position: sticky;
  top: 0;
  z-index: 999;
}
.admin-body {
  display: flex;
  height: calc(100vh - 53px);

  .active-body-wrapper {
    background-color: #f8f8f8;
    padding: 20px;
    width: 85%;
    height: calc(100vh - 53px);
    overflow: scroll;

    @include tablet-large {
      width: 83%;
    }
    @include tablet {
      width: 78%;
    }
    @include small-tablet {
      width: 72%;
    }
    .active-body {
      background-color: white;
      filter: drop-shadow(1px 0px 2px rgba(0, 0, 0, 0.15))
        drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
      height: 100%;
      overflow: scroll;
      border-radius: 16px;
    }
  }
}
