@import "../../styles/mixins";

.side-navbar {
  width: 15%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: calc(100vh - 53px);
  overflow: scroll;
  @include flex(column, flex-start, flex-start);
  padding: 16px;
  padding-left: 0px;

  @include tablet-large {
    width: 17%;
  }

  @include tablet {
    width: 22%;
  }

  @include small-tablet {
    width: 28%;
  }

  :global {
    .ant-menu-inline {
      border-inline-end: none !important;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      border-radius: 0px 12px 12px 0px;
      margin-left: 0px;
      margin-right: 0px;
      width: 100%;
    }

    .ant-menu-item-selected {
      background-color: #0c66e41a;
    }

    .ant-menu-sub.ant-menu-inline {
      background: none !important;
    }

    .ant-menu-sub .ant-menu-item {
      padding-left: 36px !important;
    }

    .ant-menu .ant-menu-submenu-arrow {
      right: 4px;

      @include mobile {
        right: 0px;
      }
    }
  }
}

.nav-item {
  @include flex();
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
  padding: 16px;
  border-radius: 0px 3px 3px 0px;

  .prefixIcon {
    width: 16px;
  }

  .nav-title {
    @include typography(var(--medium-font-weight), 14px, 17px);
    color: var(--primary-text-color);
  }
}
