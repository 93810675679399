@import "./mixins";

.auth-heading {
  color: var(--color-heading);
  font-weight: 500;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 24px;
}

.auth-links {
  padding-top: 10px;
  text-align: center;
  color: var(--color-secondary);
  font-weight: 500;

  a {
    color: var(--color-primary);
    text-decoration: none;
    padding: 0 4px;
  }
}

.filter-wrapper {
  padding: 6px;
  .reset-button-wrapper {
    @include typography(var(--medium-font-weight), 10px, 12px);
    text-align: end;
    color: var(--color-primary);

    button {
      all: unset;
      cursor: pointer;
    }
  }

  .filter-row {
    @include flex(row, space-between);
    gap: 16px;
    margin-bottom: 8px;
    padding-top: 8px;

    .filter-title {
      width: 120px;
      @include typography(var(--regular-font-weight), 12px, 21px);
    }

    :global {
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }

    .filter-select {
      width: 180px;

      :global {
        .ant-picker {
          width: 100%;
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          border-width: 0 0 0.5px 0;
          border-radius: 0px;
        }

        .ant-select {
          width: 100%;

          .ant-select-selector {
            border: 0.5px solid rgba(0, 0, 0, 0.1);
            border-width: 0 0 0.5px 0;
            border-radius: 0px;
          }

          .ant-select-selection-placeholder {
            @include typography(var(--regular-font-weight), 12px, 21px);
            align-self: center;
          }
        }

        .ant-picker-input input::placeholder {
          @include typography(var(--regular-font-weight), 12px, 21px);
        }
      }
    }
  }

  .filter-button {
    @include flex(row, flex-end);
    text-align: end;
    margin-top: 16px;
    gap: 16px;

    button {
      height: 32px !important;
      padding: 6px 12px !important;
      width: fit-content !important;
      font-size: 12px !important;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-select-dropdown .ant-select-item-option-content {
      white-space: normal;
    }
  }
}

.general-heading {
  color: var(--color-detail-heading);
  @include typography(var(--medium-font-weight), 14px, 22px);
}

.general-value {
  color: var(--color-detail-value);
  @include typography(var(--medium-font-weight), 14px, 22px);
}
