@import "../../styles/mixins";

.footer {
  border-top: 1px solid #091e4224;
  background: white;
  padding: 36px 0 24px;

  .content-wrapper {
    @include flex(row, space-between);
    flex-wrap: wrap;
    gap: 24px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--color-secondary);

    .logo-img {
      width: 140px;
    }

    .details {
      .company {
        color: var(--color-heading);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .contact,
      .address {
        max-width: 310px;
        margin: 4px 0;

        a {
          color: var(--color-secondary);
        }
      }

      .icons-wrapper {
        margin-top: 12px;
        @include flex(row, flex-start);
        gap: 8px;

        .icon {
          width: 30px;
        }
      }
    }
  }

  .disclaimer {
    margin: 20px auto 0;
    max-width: 80%;
    color: var(--color-secondary);
    @include typography(normal, 11px, 14px);

    @include mobile {
      max-width: 100%;
    }
  }

  @include mobile {
    padding: 24px 24px 16px;

    .content-wrapper {
      .logo-img {
        width: 100px;
      }

      .details {
        margin-top: 10px;
      }
    }
  }
}
