@import "../../../../../styles/mixins";

.header {
  @include flex();
  justify-content: space-between;
  padding: 9px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;

  .logo-section {
    @include flex();
    gap: 8px;
    height: unset;
    padding: 0;
  }

  .right-section {
    display: flex;
    align-items: center;
    gap: 16px;

    .sync-btn {
      height: 34px;
      padding: 6px 12px !important;
    }
  }
}

.profile-popover {
  :global {
    .ant-popover-content .ant-popover-inner {
      padding: 16px 16px 12px;
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
    }
  }

  .profile-popover-title-wrapper {
    display: flex;
    gap: 12px;
    margin-bottom: 20px;

    .profile-popover-avatar {
      min-width: 40px;
    }

    .profile-popover-name {
      font-size: 16px;
      line-height: 20px;
    }

    .profile-popover-email {
      font-size: 12px;
      font-weight: normal;
    }
  }

  .profile-popover-content-wrapper {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    align-items: center;

    .profile-popover-content-password {
      display: flex;
      gap: 5px;
      padding: 0;
      color: black;

      :global {
        .anticon {
          font-size: 16px;
        }
        .ant-btn {
          padding: 0px;
          color: rgb(0, 0, 0);
          height: auto;
        }
      }

      p,
      span {
        font-size: 13px;
        cursor: pointer;
        font-weight: 500;
      }
    }

    .profile-popover-content-logout {
      display: flex;
      gap: 5px;
      align-items: center;
      color: var(--color-primary);
      cursor: pointer;
      padding: 0px;
      height: auto;

      path {
        fill: var(--color-primary);
      }

      p,
      span {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}
