@import "./variables";
@import "./antd";
@import "./mixins";
@import "./resets";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

ul,
ol {
  margin: 0.5em 0;
  padding-inline-start: 1.5em;
  line-height: 1.5;

  ul,
  ol {
    margin: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed;
}

// Global styles for table tabs
.tabs-wrapper {
  padding: 0;

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-nav-wrap {
    padding: 0px 16px;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
    .ant-tabs-nav-list {
      gap: 16px;

      .ant-tabs-tab {
        padding: 16px 4px;
        @include typography(var(--medium-font-weight), 14px, 18px);
        color: rgba(0, 0, 0, 0.6);
      }

      .ant-tabs-ink-bar {
        height: 4px;
        border-radius: 3px 3px 0px 0px;
      }
    }
  }
}

.ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 6px 12px !important;
  * {
    display: flex;
    gap: 10px;
  }
}

.red {
  color: #ea3d3d;
}
