@import "./_breakpoints.scss";

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

// CSS mixins
@mixin flex($direction: row, $justify-content: center, $align-items: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
}
@mixin typography($fontWeight, $fontSize, $lineHeight: unset) {
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
}

//Responsive Mixins

// SmartPhones
@mixin mobile {
  @media screen and (max-width: calc($mobile-small - 1px)) {
    @content;
    // <=575px
  }
}
// Small tablets and large smartphones (landscape view)
@mixin small-tablet {
  @media screen and (min-width: $mobile-small) and (max-width: calc($mobile - 1px)) {
    @content;
    // 576px - 767px
  }
}

// Small tablets (portrait view)
@mixin tablet {
  @media screen and (min-width: #{$mobile}) and (max-width: calc($tablet - 1px)) {
    @content;
    //768px - 991px
  }
}

// Tablets and small desktops
@mixin tablet-large {
  @media screen and (min-width: #{$tablet}) and (max-width: calc($desktop - 1px)) {
    @content;
    //992px - 1199px
  }
}

// Large tablets and desktops
@mixin desktop {
  @media screen and (min-width: #{$desktop}) and (max-width: calc($desktop-large - 1px)) {
    @content;
    //1200px - 1399px
  }
}

//Large Desktops
@mixin large-desktop {
  @media screen and (min-width: calc($desktop-large + 1px)) {
    @content;
    //   >= 1400px
  }
}
