@import "../../styles//extended";

.reset-form {
  width: 50%;
  h1 {
    @extend .auth-heading;
  }
  a {
    color: var(--color-primary);
    font-weight: 500;
  }
  button {
    margin-top: 24px;
  }
}
