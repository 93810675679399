:root {
  --color-primary: #0c66e4;
  --color-primary-light: #6193ed;
  --color-primary-lightest: #0c66e41a;
  --color-secondary: #626f86;
  --color-heading: #172b4d;
  --color-field-required: #ae2a19;
  --color-nav-item: #44546f;
  --color-auth-background: #f1f2f3;
  --color-image-background: #5596f0cc;
  --color-description-glass-background: #fff2f221;
  --color-input-border: #091e4224;
  --color-button-primary: #091e420f;
  --colr-radio-checked: #091e4208;
  --color-content-background: #f9fbfc;
  --color-select-dropdown-box-shadow: #091e4226;
  --color-secondary-bg: #f5f5f5;
  --color-role-secondary: #969696;
  --color-feature-btn: #898989;
  --color-detail-heading: #1a1a1ab2;
  --color-detail-heading-light: #1a1a1a99;
  --color-detail-value: #1a1a1a;
  --color-tab-heading: #44546f;
  --color-tab-active: #6666c4;

  --regular-font-weight: 400;
  --medium-font-weight: 500;
  --bold-font-weight: 600;
  --bolder-font-weight: 700;
}
