@import "../../styles//extended";

.login-form {
  width: 50%;
  h1 {
    @extend .auth-heading;
  }
  a {
    color: var(--color-primary);
    font-weight: 500;
  }

  .back-to-login {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
