.mobile {
  :global {
    .PhoneInputCountry {
      margin-right: 8px;
    }
    .PhoneInputCountryIcon {
      transform: scale(1.2);
      margin-right: 4px;
      margin-left: 4px;
    }
  }
  input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    height: 38px;
    box-shadow: none;
    border-radius: 4px;
    padding: 4px 11px;
  }
  &.disable-flag-selection {
    select {
      pointer-events: none;
    }
    :global {
      .PhoneInputCountrySelectArrow {
        display: none !important;
      }
    }
  }
}
