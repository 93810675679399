@import "../../../styles/mixins";

.private-header {
  display: flex;
  background: white;
  align-items: center;
  border-bottom: 1px solid #091e4224;
  padding: 0px 0px 2px 0px;
  position: sticky;
  top: 0;
  z-index: 1000;

  @include mobile {
    padding: 15px;
  }

  .header-content {
    display: flex;
    margin-bottom: 0px;
    align-items: center;

    .private-logo {
      cursor: pointer;
      width: 130px;
    }
  }

  .header-image-container {
    display: flex;
    gap: 22px;
    align-items: center;
  }

  :global {
    .ant-menu.ant-menu-light.ant-menu-horizontal {
      border-bottom: 0px;
      flex: auto;
      min-width: 0;
      margin-left: 20px;
      padding-top: 2px;

      li {
        font-size: 15px;
        font-weight: 500;
        color: var(--color-nav-item);

        &::after {
          bottom: 1px;
          border-bottom-width: 3px;
        }
      }
    }
  }
}

.tour {
  :global {
    .ant-tour-cover img {
      border: 1px solid var(--color-input-border);
      border-radius: 8px;
    }

    .ant-btn {
      width: auto;
    }
  }
}

.profile-popover {
  :global {
    .ant-popover-content .ant-popover-inner {
      padding: 16px 16px 12px;
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
    }
  }

  .profile-popover-title-wrapper {
    display: flex;
    gap: 12px;
    margin-bottom: 20px;

    .profile-popover-avatar {
      min-width: 40px;
    }

    .profile-popover-name {
      font-size: 16px;
      line-height: 20px;
    }

    .profile-popover-email {
      font-size: 12px;
      font-weight: normal;
    }
  }

  .profile-popover-content-wrapper {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    align-items: center;

    .profile-popover-content-password {
      display: flex;
      gap: 5px;
      padding: 0;
      color: black;

      :global {
        .anticon {
          font-size: 16px;
        }
        .ant-btn {
          padding: 0px;
          color: rgb(0, 0, 0);
          height: auto;
        }
      }

      p,
      span {
        font-size: 13px;
        cursor: pointer;
        font-weight: 500;
      }
    }

    .profile-popover-content-logout {
      display: flex;
      gap: 5px;
      align-items: center;
      color: var(--color-primary);
      cursor: pointer;
      padding: 0px;
      height: auto;

      path {
        fill: var(--color-primary);
      }

      p,
      span {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}
