@import "../../styles/extended";
@import "../../styles/mixins";

.login-form {
  width: 50%;

  h1 {
    @extend .auth-heading;
  }

  a {
    color: var(--color-primary);
    font-weight: 500;
  }

  .login-button {
    margin-top: 24px;
  }

  .mobile-input-wrapper {
    height: 32px;

    input {
      height: 32px;
    }
  }
}

@include mobile {
  .login-form {
    width: 100%;
    padding: 52px 20px 0;
  }
}
