@import "../../styles/mixins";

.modal {
  :global {
    .ant-modal-content .ant-modal-footer {
      background-color: transparent;
    }
  }
}

.password-validation-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 20px;
  margin: 0px 0px 19px 4px;

  .validation {
    align-items: center;
    display: flex;
    gap: 8px;

    .dot {
      background-color: #d9d9d9;
      border-radius: 50px;
      height: 8px;
      width: 8px;
    }

    .validation-name {
      color: rgba(0, 0, 0, 0.4);
      @include typography(var(--regular-font-weight), 12px, 21px);
    }
  }
  .validated {
    .dot {
      background-color: #14b467;
    }
    .validation-name {
      color: #14b467;
    }
  }
}
